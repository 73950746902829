import styled, { createGlobalStyle } from "../../../theme/styled";

// MARK: Global CSS

// tslint:disable-next-line:no-unused-expression
export const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Raleway|Work+SansWork+Sans:600');
    
    * {
        box-sizing: border-box;
        margin: 0;
        -webkit-overflow-scrolling: touch;
    }

    html, body {
      ${({ theme }) => theme.font.size.base.css};
      background-color: ${({ theme }) => theme.color.values.white};
      max-width: 100vw;
      overflow-x: hidden;
    }
`;

// MARK: Components

export const CaseStudyContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const NavigationConatiner = styled.div`
  z-index: 5;
  margin: 0 24px;

  ${({ theme: localTheme }) => localTheme.media.tabletMin`
    min-width: 680px;
    width: 100%;
    max-width: calc(100vw - 80px);
    margin: 0 auto;
  `};

  ${({ theme: localTheme }) => localTheme.media.tabletLandscapeMin`
    min-width: 936px;
    max-width: calc(100vw - 80px);
  `};

  ${({ theme: localTheme }) => localTheme.media.desktopMin`
    width: calc(100vw - 160px);
    max-width: 1128px;
  `};
`;

export const FooterContainer = styled.div`
  ${({ theme }) => theme.content.defaultWidth};
  margin-top: 96px;

  ${({ theme }) => theme.media.tabletMin`
      margin-top: 144px;
  `};
`;
