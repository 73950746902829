import { Link } from "gatsby";
import React from "react";
import * as Scroll from "react-scroll";
import { ReactScrollLinkProps } from "react-scroll/modules/components/Link";
import { FlattenInterpolation, ThemeProps } from "styled-components";

import styled, { css } from "../../theme/styled";

import { ActionTitle } from "../typo";

import circleRightArrowIcon from "../../images/icons/circle-arrow-right.svg";

// MARK: Styled

const containerStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 0.24s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    opacity 0.24s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  user-select: none;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    opacity: 0.8;
  }

  &:active {
    transform: translateY(0px);
  }
`;

const ContainerAsButton = styled.button`
  outline: none;
  border: none;
  background: none;
  padding: 0;

  ${containerStyles};
`;

const ContainerAsLink = styled.a`
  ${containerStyles};
  text-decoration: none;
`;

const ContainerAsLocalLink = styled(Link)`
  ${containerStyles};
  text-decoration: none;
`;

const ContainerAsScrollLink = styled(Scroll.Link)`
  ${containerStyles};
`;

const Title = styled(ActionTitle)<{
  customStyles?: Array<FlattenInterpolation<ThemeProps<any>>>;
}>`
  line-height: 1;

  ${({ customStyles }) => customStyles};
`;

const Icon = styled.img<{
  customStyles?: Array<FlattenInterpolation<ThemeProps<any>>>;
}>`
  display: block;
  margin-left: 8px;

  ${({ theme }) => theme.media.tabletLandscapeMin`
      height: 20px;
  `};

  ${({ customStyles }) => customStyles};
`;

// MARK: Render

interface ActionDisplaySettings {
  title: string;
  icon?: string;
  customTitleStyles?: Array<FlattenInterpolation<ThemeProps<any>>>;
  customIconStyles?: Array<FlattenInterpolation<ThemeProps<any>>>;
}

const ActionContent: React.FC<{
  displaySettings: ActionDisplaySettings;
}> = ({ displaySettings }) => {
  const { title, icon, customTitleStyles, customIconStyles } = displaySettings;
  return (
    <>
      <Title customStyles={customTitleStyles} children={title} />
      <Icon
        customStyles={customIconStyles}
        src={icon || circleRightArrowIcon}
        alt="Action icon"
      />
    </>
  );
};

// MARK: Action local link

export const ActionLocalLink: React.FC<{
  displaySettings: ActionDisplaySettings;
  to: string;
}> = ({ displaySettings, to }) => (
  <ContainerAsLocalLink to={to}>
    <ActionContent displaySettings={displaySettings} />
  </ContainerAsLocalLink>
);

// MARK: Action link

export const ActionLink: React.FC<{
  anchorAttributes: React.AnchorHTMLAttributes<any>;
  displaySettings: ActionDisplaySettings;
}> = ({ anchorAttributes, displaySettings }) => (
  <ContainerAsLink {...anchorAttributes}>
    <ActionContent displaySettings={displaySettings} />
  </ContainerAsLink>
);

// MARK: Action scroll link

export const ActionScrollLink: React.FC<{
  displaySettings: ActionDisplaySettings;
  scrollSettings: ReactScrollLinkProps;
}> = ({ displaySettings, scrollSettings }) => (
  <ContainerAsScrollLink {...scrollSettings}>
    <ActionContent displaySettings={displaySettings} />
  </ContainerAsScrollLink>
);

// MARK: Action button

export const ActionButton: React.FC<{
  displaySettings: ActionDisplaySettings;
  onClick?: () => void;
}> = ({ displaySettings, onClick }) => (
  <ContainerAsButton onClick={onClick}>
    <ActionContent displaySettings={displaySettings} />
  </ContainerAsButton>
);
