import { graphql } from "gatsby";
import React from "react";
import rehypeReact from "rehype-react";

import { ActionLink, ActionLocalLink } from "../../components/actionButton";
import CaseStudyLayout from "../../components/layout/caseStudy";

import * as Components from "./components";
import * as Styled from "./styled";

// MARK: Render AST

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: Styled.H1,
    h2: Components.AnimatedH2,
    p: Components.AnimatedParagraph,
    ul: Components.AnimatedUnorderedList,
    "horizontal-image-slider": Components.HorizontalImageSlider,
    video: Components.Video,
    "youtube-video": Components.YouTubeVideo
  }
}).Compiler;

// MARK: Template

interface CaseStudyTemplateProps {
  data: any;
}

const CaseStudyTemplate: React.FC<CaseStudyTemplateProps> = ({ data }) => {
  // > MARK: Variables

  const { markdownRemark, heroImageFile, nextMarkdownRemark } = data;
  const { htmlAst, frontmatter } = markdownRemark;
  const { title, seo, intro, hero } = frontmatter;
  const { description: seoDescription } = seo;
  const { childImageSharp: heroChildImageSharp } = heroImageFile;

  const { frontmatter: nextFrontmatter } = nextMarkdownRemark;
  const { title: nextTitle, slug: nextSlug } = nextFrontmatter;

  const introPargraphWithNewline = applyNewlineToText(intro);

  // > MARK: Render

  return (
    <CaseStudyLayout
      title={`Case Study - ${title}`}
      description={seoDescription}
    >
      {
        // >> MARK: Hero section
      }
      <Styled.HeroSection
        backgroundColor={hero.style.backgroundColor}
        removeBottomPadding={hero.style.removeBottomPadding}
      >
        <Styled.HeroIntroParagraph
          textColor={hero.style.textColor}
          data-aos="fade-up"
        >
          {introPargraphWithNewline}
        </Styled.HeroIntroParagraph>
        <Styled.HeroImage
          fluid={heroChildImageSharp.fluid}
          title={`${title} hero image`}
          alt={`${title} hero image`}
        />
      </Styled.HeroSection>

      {
        // >> MARK: Content
      }
      <Styled.ContentContainer>{renderAst(htmlAst)}</Styled.ContentContainer>

      {
        // >> MARK: Actions section
      }
      <Styled.ActionsSection data-aos="fade-right">
        <ActionLink
          anchorAttributes={{
            href: "mailto:hello@oroboro.studio?Subject=Hello%20Oroboro%20Team"
          }}
          displaySettings={{
            title: "Contact us"
          }}
        />
        <ActionLocalLink
          to={`/case-studies/${nextSlug}`}
          displaySettings={{
            title: `Read "${nextTitle}"`
          }}
        />
        <ActionLocalLink
          to="/"
          displaySettings={{
            title: "Go back home"
          }}
        />
      </Styled.ActionsSection>
    </CaseStudyLayout>
  );
};

export default CaseStudyTemplate;

// MARK: Page query

export const pageQuery = graphql`
  query($id: String!, $heroImageRelativePath: String!, $nextId: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        seo {
          description
        }
        intro
        hero {
          style {
            textColor
            backgroundColor
            removeBottomPadding
          }
        }
      }
    }
    heroImageFile: file(relativePath: { eq: $heroImageRelativePath }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # Next case study
    nextMarkdownRemark: markdownRemark(id: { eq: $nextId }) {
      frontmatter {
        title
        slug
      }
    }
  }
`;

// MARK: Helpers

const applyNewlineToText = (text: string) => {
  return text.split("\n").map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
        <br />
      </React.Fragment>
    );
  });
};
