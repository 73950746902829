import Img from "gatsby-image";

import styled from "../../theme/styled";

// MARK: Content

export const ContentContainer = styled.main`
  .gatsby-resp-image-link {
    margin-top: 96px;
    margin-left: -24px;
    margin-right: -24px;

    ${({ theme }) => theme.media.tabletMin`
      margin-top: 144px;
      width: 100vw;
      margin-left: calc((100vw - 496px) / -2)
    `};

    ${({ theme }) => theme.media.tabletLandscapeMin`
      margin-top: 176px;  
      margin-left: calc((100vw - 600px) / -2)
    `};
  }
`;

export const H1 = styled.h1`
  display: none;
`;

export const H2 = styled.h2`
  ${({ theme }) => theme.font.style.workSans};
  ${({ theme }) => theme.font.size.normal.cssWithLineHeightRatio()};
  ${({ theme }) => theme.content.defaultWidth};

  margin-top: 96px;
  font-weight: 600;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 144px;
    margin-left: auto;
    margin-right: auto;
    max-width: 496px;
    min-width: 496px;

    ${theme.font.size.mediumLarge.cssWithLineHeightRatio(2)};
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
    margin-top: 176px;
    max-width: 600px;
    min-width: 600px;

    ${theme.font.size.large.cssWithLineHeightRatio(2)};
  `};
`;

export const Paragraph = styled.p`
  ${({ theme }) => theme.font.style.raleway};
  ${({ theme }) => theme.font.size.normal.cssWithLineHeightRatio(2)};
  ${({ theme }) => theme.content.defaultWidth};

  margin-top: 32px;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    max-width: 496px;
    min-width: 496px;

    ${theme.font.size.medium.cssWithLineHeightRatio(2)};
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
    max-width: 600px;
    min-width: 600px;

    ${theme.font.size.mediumLarge.cssWithLineHeightRatio(2)};
  `};
`;

export const UnorderedList = styled.ul`
  ${({ theme }) => theme.content.defaultWidth};
  ${({ theme }) => theme.font.style.raleway};
  ${({ theme }) => theme.font.size.normal.cssWithLineHeightRatio(2)};

  margin-top: 32px;
  list-style-type: none;
  list-style-position: outside;
  padding: 0;

  li {
    margin-top: 8px;
    color: ${({ theme }) => theme.color.values.grey};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.values.activeBlue};
    transition: margin-left 0.3s ease-out;

    &:hover {
      margin-left: 4px;
      opacity: 0.8;
    }
  }

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    max-width: 496px;
    min-width: 496px;

    ${theme.font.size.medium.cssWithLineHeightRatio(2)};
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
    max-width: 600px;
    min-width: 600px;
    ${theme.font.size.mediumLarge.cssWithLineHeightRatio(2)};
  `};
`;

// MARK: Hero section

export const HeroSection = styled.section<{
  removeBottomPadding?: boolean;
  backgroundColor: string;
}>`
  margin-top: -40px;
  padding-top: 120px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ removeBottomPadding }) =>
    !removeBottomPadding &&
    `
    padding-bottom: 56px;
  `}

  ${({ theme, removeBottomPadding }) => theme.media.tabletMin`
    margin-top: -62px;
    padding-top: 240px;

    ${!removeBottomPadding &&
      `
      padding-bottom: 72px;
    `}
  `};
`;

export const HeroIntroParagraph = styled.p<{
  textColor?: string;
}>`
  color: ${({ theme, textColor }) => textColor || theme.color.values.white};
  ${({ theme }) => theme.font.style.raleway};
  ${({ theme }) => theme.font.size.mediumLarge.cssWithLineHeightRatio()};
  ${({ theme }) => theme.content.defaultWidth};

  ${({ theme }) => theme.media.tabletMin`
    max-width: 680px;
    min-width: 680px;
    ${theme.font.size.xLarge.cssWithLineHeightRatio()};  
  `};
`;

export const HeroImage = styled(Img)`
  margin-top: 56px;
  display: block;
  width: 100vw;
  height: auto;
`;

// MARK: ACtions

export const ActionsSection = styled.section`
  ${({ theme }) => theme.content.defaultWidth};

  margin-top: 96px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;

  border-top: solid 1px #eee;

  * {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 144px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 72px;
    max-width: 496px;
    min-width: 496px;

    ${theme.font.size.medium.cssWithLineHeightRatio(2)};
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
    max-width: 600px;
    min-width: 600px;
    ${theme.font.size.mediumLarge.cssWithLineHeightRatio(2)};
  `};
`;

// MARK: Horizontal image slider

export const HorizontalSliderContainer = styled.div`
  margin-top: 96px;
  padding: 56px 0;
  width: 100vw;
  max-width: 100vw;
  overflow-x: scroll;
  background-color: ${({ theme }) => theme.color.values.black};

  ::-webkit-scrollbar {
    width: 0px;
  }

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 144px;
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
    margin-top: 176px;  
  `};
`;

export const HorizontalSliderContentContainer = styled.div<{
  ratio: number;
}>`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  width: fit-content;

  ${({ theme }) => theme.media.tabletMin`
      padding: 0 40px;
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
      padding: 0 56px;
  `};

  ${({ theme }) => theme.media.desktopMin`
      padding: 0 calc((100vw - 1128px)/2);
  `};

  .gatsby-resp-image-link {
    margin: 0;
    display: block;
    width: 64vw;
    height: calc(64vw * ${({ ratio }) => ratio});

    &:not(:first-child) {
      margin-left: 32px;
    }

    ${({ theme, ratio }) => theme.media.tabletMin`
        width: 26vw;
        height: calc(26vw * ${ratio});
    `};

    ${({ theme, ratio }) => theme.media.tabletLandscapeMin`
        width: 23vw;
        height: calc(23vw * ${ratio});
    `};

    ${({ theme }) => theme.media.desktopMin`
      &:not(:first-child) {
        margin-left: 48px;
      }
    `};
  }

  .gatsby-resp-image-background-image {
    background-image: none !important;
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
  }
`;

// MARK: Video

export const Video = styled.video`
  margin-top: 96px;
  width: 100vw;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 144px;
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
    margin-top: 176px;  
  `};
`;

export const IFrame = styled.iframe<{
  height: number;
  width: number;
}>`
  margin-top: 96px;
  width: 100vw;
  height: ${({ height, width }) => `calc(100vw * ${height / width})`};
  border: none;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 144px;
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
    margin-top: 176px;  
  `};
`;
