import React from "react";
import { Helmet } from "react-helmet";

// animate on scroll
import AOS from "aos";
import "aos/dist/aos.css";

import theme from "../../../theme";
import { ThemeProvider } from "../../../theme/styled";

import * as Styled from "./styled";

import Footer from "../../footer";
import Navigation from "../../navigation";

interface CaseStudyLayoutProps {
  title: string;
  description: string;
  backgroundColor?: string;
  backgroundImageUrl?: string;
}

export default class CaseStudyLayout extends React.Component<
  CaseStudyLayoutProps
> {
  // MARK: Life cycle

  public componentDidMount() {
    // initialize animate on scroll (AOS)
    AOS.init({
      duration: 1000,
      once: true
    });
  }

  // MARK: Render

  public render() {
    const { title, description } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Styled.CaseStudyContainer>
          <Styled.GlobalStyles />

          {
            // > MARK: Head
          }
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
          </Helmet>

          {
            // > MARK: Navigation
          }
          <Styled.NavigationConatiner>
            <Navigation isNavBarColorInverted={true} />
          </Styled.NavigationConatiner>

          <div data-aos="fade">
            {
              // > MARK: Children
            }
            {this.props.children}

            {
              // > MARK: Footer
            }
            <Styled.FooterContainer>
              <Footer />
            </Styled.FooterContainer>
          </div>
        </Styled.CaseStudyContainer>
      </ThemeProvider>
    );
  }
}
