import { withPrefix } from "gatsby";
import React from "react";
import * as Styled from "./styled";

export const Video: React.FC<{
  pathtosrc: string;
}> = ({ pathtosrc }) => {
  return (
    <Styled.Video autoPlay={true} loop={true} muted={true} data-aos="fade">
      <source src={withPrefix(`${pathtosrc}.webm`)} type="video/webm" />
      <source src={withPrefix(`${pathtosrc}.mov`)} type="video/mp4" />
    </Styled.Video>
  );
};

export const YouTubeVideo: React.FC<{
  width: string;
  height: string;
  src: string;
}> = ({ width, height, src }) => {
  return (
    <Styled.IFrame
      width={parseInt(width, 10)}
      height={parseInt(height, 10)}
      src={src}
      allow="autoplay"
      data-aos="fade-up"
    />
  );
};

export const HorizontalImageSlider: React.FC<{
  imageratio: number;
}> = ({ children, imageratio: imageRatio }) => (
  <Styled.HorizontalSliderContainer>
    <Styled.HorizontalSliderContentContainer
      ratio={imageRatio}
      data-aos="fade-left"
    >
      {children}
    </Styled.HorizontalSliderContentContainer>
  </Styled.HorizontalSliderContainer>
);

export const AnimatedH2: React.FC = ({ children }) => (
  <Styled.H2 children={children} data-aos="fade-up" />
);

export const AnimatedParagraph: React.FC = ({ children }) => (
  <Styled.Paragraph children={children} data-aos="fade-up" />
);

export const AnimatedUnorderedList: React.FC = ({ children }) => (
  <Styled.UnorderedList children={children} data-aos="fade-up" />
);
